<template>
  <datepicker
    class="ui-datepicker"
    v-model="internalValue"
    :enable-time-picker="false"
    :month-change-on-scroll="false"
    :clearable="false"
    :min-date="minDate"
    :start-date="minDate"
    :max-date="maxDate"
    :prevent-min-max-navigation="true"
    :disabled-dates="disabledDates"
    auto-apply
    position="left"
    teleport="body"
    :format="formatDate"
  >
    <template #month-year="{ month, year, months, years, updateMonthYear, handleMonthYearChange }">
      <div class="ui-datepicker__head">
        <span
          class="ui-datepicker__head-icon is-prev"
          @click="handleMonthYearChange(false)"
          :class="{ 'is-disabled': isArrowDisabled(false, month, year) }"
        >
          <icon-chevron-left />
        </span>
        <span class="ui-datepicker__head-label"> {{ formatHeadDate({ month, year, months, years }) }}</span>
        <span
          class="ui-datepicker__head-icon is-next"
          @click="handleMonthYearChange(true)"
          :class="{ 'is-disabled': isArrowDisabled(true, month, year) }"
        >
          <icon-chevron-right />
        </span>
      </div>
    </template>
    <template #dp-input="{ value }">
      <ui-input class="ui-datepicker__input" :value="value" readonly :error="error" placeholder="Select date" />
    </template>
  </datepicker>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import UiInput from '~/components/global/ui/UiInput.vue';
import { formatDate } from '~/utils/date';
import { ref, toRefs, watch } from '#imports';
import {
  addMonths,
  addYears,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  isSunday,
  set,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  subMonths,
} from 'date-fns';
import IconChevronLeft from '~/components/global/elements/IconChevronLeft.vue';
import IconChevronRight from '~/components/global/elements/IconChevronRight.vue';

type DateValue = Date | string | null;

const props = defineProps({
  modelValue: {
    type: [String, Object] as PropType<Date>,
    default: null,
  },
  error: {
    type: Boolean,
    default: false,
  },
  minDate: {
    type: Date as PropType<Date>,
    default: () => new Date(),
  },
  maxDate: {
    type: Date as PropType<Date>,
    default: () => addYears(new Date(), 1),
  },
});

const emit = defineEmits(['update:modelValue']);
const { modelValue } = toRefs(props);

const internalValue = ref(modelValue.value);

const disableDatesRanges: Date[][] = [
  // christmas
  [new Date(2023, 11, 23), new Date(2023, 11, 29)],
];

const disabledDates = (date: Date) => {
  if (isSunday(date)) {
    return true;
  }
  for (const pairs of disableDatesRanges) {
    if (isAfter(date, pairs[0]) && isBefore(date, pairs[1])) {
      return true;
    }
  }
  return false;
};

const isArrowDisabled = (next: boolean, month: any, year: any) => {
  return validateMonthYear(set(new Date(), { month, year }), next);
};

const formatHeadDate = ({ month, year, months }: { month: any; year: any; months: any }) => {
  return month && year && months ? `${months[month].text} ${year}` : '';
};

watch(internalValue, (val) => emit('update:modelValue', val));
watch(modelValue, (val) => (internalValue.value = val));

const getDate = (value?: any) => (value ? new Date(value) : new Date());

const resetDateTime = (value: Date | string): Date => {
  let dateParse = getDate(JSON.parse(JSON.stringify(value)));
  dateParse = setHours(dateParse, 0);
  dateParse = setMinutes(dateParse, 0);
  dateParse = setSeconds(dateParse, 0);
  dateParse = setMilliseconds(dateParse, 0);

  return dateParse;
};

const validateMonthYear = (date: Date, next: boolean) => {
  if (next && !props.maxDate) {
    return false;
  }
  if (!next && !props.minDate) {
    return false;
  }
  const compareDate = next ? addMonths(date, 1) : subMonths(date, 1);
  const monthYear: [number, number] = [getMonth(compareDate), getYear(compareDate)];
  return next ? !validateMaxDate(...monthYear, props.maxDate as Date) : !validateMinDate(...monthYear, props.minDate as Date);
};

const validateMinDate = (month: number, year: number, minDate: Date): boolean => {
  return isDateBefore(...getDateForCompare(minDate, month, year)) || isDateEqual(...getDateForCompare(minDate, month, year));
};

const validateMaxDate = (month: number, year: number, maxDate: Date): boolean => {
  return isDateAfter(...getDateForCompare(maxDate, month, year)) || isDateEqual(...getDateForCompare(maxDate, month, year));
};

const getDateForCompare = (date: Date | string, month: number, year: number): [Date, Date] => {
  return [set(getDate(date), { date: 1 }), set(getDate(), { month, year, date: 1 })];
};

const isDateBefore = (date: DateValue, dateToCompare: DateValue): boolean => {
  if (!date || !dateToCompare) {
    return false;
  }
  return isBefore(resetDateTime(date), resetDateTime(dateToCompare));
};

const isDateEqual = (date: DateValue, dateToCompare: DateValue): boolean => {
  if (!date || !dateToCompare) {
    return false;
  }
  return isEqual(resetDateTime(date), resetDateTime(dateToCompare));
};

const isDateAfter = (date: DateValue, dateToCompare: DateValue): boolean => {
  if (!date || !dateToCompare) {
    return false;
  }
  return isAfter(resetDateTime(date), resetDateTime(dateToCompare));
};
</script>

<style lang="scss" scoped>
.ui-datepicker {
  :deep(input) {
    cursor: pointer;
  }

  &__head {
    display: grid;
    grid-template-columns: rem(30) 1fr rem(30);
    width: 100%;
    text-align: center;
    align-items: center;
    &-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(30);
      height: rem(30);
      &.is-prev {
        justify-content: flex-start;
      }
      &.is-next {
        justify-content: flex-end;
      }
      svg {
        width: rem(18);
        height: rem(18);
      }
      &.is-disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}
</style>
